function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}



document.addEventListener('DOMContentLoaded', function () {
    const searchInput = document.getElementById('art-search-input');
    const searchIconDesktop = document.getElementById("searchIcon");
    const searchIconMobile = document.getElementById("searchIconMobile");
    const searchOverlay = document.getElementById("searchOverlay");
    const closeSearchButton = document.getElementById("closeSearch");
    const resultsContainer = document.getElementById('searchResults');

    // Add event listeners for both desktop and mobile search icons
    if (searchIconDesktop) {
        searchIconDesktop.addEventListener("click", function (event) {
            event.preventDefault();
            searchOverlay.classList.add("active");
            setTimeout(() => {
                searchInput.focus();
            }, 500)
        });
    }

    if (searchIconMobile) {
        searchIconMobile.addEventListener("click", function (event) {
            event.preventDefault();
            searchOverlay.classList.add("active");
            setTimeout(() => {
                searchInput.focus();
            }, 500)
        });
    }

    // Close button to remove the overlay
    if (closeSearchButton) {
        closeSearchButton.addEventListener("click", function () {
            searchOverlay.classList.remove("active");
            searchInput.value = ''
            resultsContainer.innerHTML = ''
            resultsContainer.classList.remove('show');
        });
    }

    if (searchOverlay) {
        searchOverlay.addEventListener('click', function (event) {
            if (event.target === searchOverlay) {
                searchOverlay.classList.remove('active');
                searchInput.value = ''
                resultsContainer.innerHTML = ''
                resultsContainer.classList.remove('show');
            }
        });
    }

    searchInput.addEventListener('input', debounce(function () {
        const query = this.value;
        if (query.length > 0) {
            fetch(`${wpData.ajax_url}?action=search_art_posts&query=${query}`)
                .then(response => response.json())
                .then(data => {

                    resultsContainer.innerHTML = '';
                    if (data.length > 0) {
                        resultsContainer.classList.add('show');
                        data.forEach(item => {
                            const resultItem = document.createElement('div');
                            resultItem.classList.add('list-group-item'); // Bootstrap class for list items

                            // Create result HTML with image and title using Bootstrap classes
                            resultItem.innerHTML = `
                            <a href="${item.url}" class="d-flex flex-column flex-sm-row align-items-center list-group-item-action">
                                <img src="${item.image}" alt="${item.title}" class="img-thumbnail me-3 img-fluid result-image">
                                <div class="flex-grow-1 d-flex flex-column flex-sm-row justify-content-between align-items-baseline">
                                    <div class="d-flex flex-column flex-sm-row align-items-baseline">
                                        <h5 class="mb-0 me-2 text-truncate">${item.title}</h5>
                                        <small class="text-muted me-2 d-none d-sm-inline">${item.medium ? item.medium : ''}</small>
                                        <small class="text-muted d-none d-sm-inline">${item.dimensions ? item.dimensions : ''}</small>
                                    </div>
                                </div>
                            </a>
                        `;


                            resultsContainer.appendChild(resultItem);
                        });
                    } else {
                        resultsContainer.innerHTML = '<p class="text-muted">No results found.</p>';
                    }
                });
        } else {
            document.getElementById('searchResults').innerHTML = '';
        }
    }));

});
